.dropContainer {
  background-color: white;
  display: flex;
  border-radius: 4px;
  border: 1px solid rgb(207, 204, 204);
  align-items: center;
  padding-left: 0.2rem;
  p {
    font-size: 14px;
    margin: 0;
    flex: 1;
  }
}
.menuContainer {
  .button {
    color: rgb(110, 108, 108) !important;
    padding: 0;
    background-color: transparent;
    border: none !important;
  }
  .button:hover {
    background-color: transparent !important;
  }
}
.container {
  // padding: 0.4rem;
  :hover {
    backdrop-filter: brightness(85%);
  }
  .actionContainer {
    margin-bottom: 0.6rem;
    padding: 0 0.5rem;
    .iconSide {
      width: 20px;
    }
    cursor: pointer;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: black;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.negativeAction {
  svg {
    color: #f46a6a;
  }
}
.positiveAction {
  svg {
    color: #556ee6;
  }
}
.successAction {
  svg {
    color: #34c38f;
  }
}
.warningAction {
  svg {
    color: #f1b44c;
  }
}
