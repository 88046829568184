.smallForm {
  padding-top: 0px;
}

.smallForm .form-label {
  font-size: 14px;
  white-space: nowrap; /* Add this line */
}

.smallForm .form-control {
  font-size: 14px;
}

.smallForm .btn-sm {
  font-size: 14px;
}

.form-group {
  align-items: baseline;
}

.smallForm .form-body {
  min-height: 250px;
}

.form_modal_footer {
  margin-top: 10px;
}

.autoCompleteLabel {
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}
