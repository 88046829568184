.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.label {
  font-size: 14px; /* 15px; */
  font-weight: 500;
  width: 20%;
  max-width: 150px;
}
.infoWrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 5px;
}
.inputInfo {
  flex: 1;
  font-size: 15px;
  width: 50px;
  border: 1px solid #c8c8cb;
  background: #efefef;
}
.inputDate {
  flex: 2;
  font-size: 15px;
  width: 100px;
  background: #efefef;
  border: 1px solid #c8c8cb;
}
