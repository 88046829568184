.customAutocomplete {
  width: 100%;
  height: 100%;
}

.autocomplete-input {
  width: 100%;
  height: 100%;
  color: #565656;
  border: 1px solid #069ce6;
  border-radius: 5px;
  padding: 0 5px;
}

.autocomplete-input:focus {
  outline-color: #069ce6;
  border-radius: 5px 5px 0 0;
}

.autocompleteDatalist {
  display: block;
  border: 1px solid #485ec4;
  z-index: 2;
  position: relative;
  background: white;
  border-top: none;
  padding: 5px;
  width: calc(100%);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0 5px 5px;
  .autocompleteOptions {
    background-color: white;
    padding: 4px;
    margin-bottom: 1px;
    cursor: pointer;
    white-space: pre-wrap;
    text-align: justify;
    border-bottom: 1px solid #069ce6;
    font-size: 14px;
  }
  .autocompleteOptions:hover {
    background-color: #069ce6;
    color: #fff;
  }
}
