.container {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px;
}

.wrapper span {
  font-size: 14px;
  background-color: #282c34;
  color: white;
  padding: 0.3rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover, &:focus {
    background-color: #3a3f47;
  }
}
