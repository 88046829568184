body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* override admin layout css */
.main-content {
  padding: 20px 0;
}

.page-content {
  height: calc(100vh - 20px);
  padding: 46px 2px 2px;
}

.ag-react-container {
  display: flex;
}

.dock-container {
  height: calc(100vh - (20px + 46px + 2px + 24px + 10px));
}

.dock-content-holder {
  padding-left: 8px;
  padding-right: 6px;
  background-color: #f4f4f8 !important;
}

.dock-tab-active {
  color: #190593 !important;
}

.dock-tab {
  margin-right: 20px !important;
  font-weight: 400 !important;
  font-size: 14px;
}

.dock-ink-bar {
  background-color: #190593 !important;
}

.dock-top .dock-bar {
  background-color: white !important;
}

#sidebar-menu .form-select {
  width: 90%;
  margin: auto;
  font-size: 16px;
}

.flex-1 {
  flex: 1;
}

.vertical-menu .navbar-brand-box {
  justify-content: center !important;
  align-items: center !important;
  height: 52px !important;
}

.vertical-menu .navbar-brand-box .logo-lg img {
  height: 46px !important;
}

.navbar-header {
  height: 52px !important;
}

.spinner-border {
  margin-top: 150px;
}

.my-panel-extra-btn {
  cursor: pointer;
  color: red;
  display: inline-block;
  width: 18px;
  height: 24px;
  text-align: center;
  opacity: 0.5;
  transition: all 0.25s ease-in-out;
  line-height: 30px;
}

.my-panel-extra-btn :hover {
  opacity: 1;
}

.dock-tab > div {
  padding-left: 0 !important;
  background-color: white !important;
}

.text-red {
  color: #f00;
}

.inline-error {
  color: #f00;
  font-size: 12px;
  white-space: normal;
}

.dock-tab > div {
  padding: 4px 0px;
}

.dock-tab > div:has(.dock-tab-close-btn) {
  padding: 4px 14px !important;
  padding-left: 0 !important;
}

.document-cell > .ag-cell-wrapper {
  height: 100% !important;
}

.ag-checkbox-input-wrapper input,
.ag-checkbox-input-wrapper input {
  -webkit-appearance: checkbox !important;
  appearance: checkbox !important;
  opacity: initial !important;
}
