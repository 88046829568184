/* .dropdown-container */
.dropdown-container {
  margin-bottom: 1px;
  padding: 0 !important;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // border: 1px solid #ccc;
  border-radius: 4px;
  // background-color: white;
  background-color: inherit;
  &:after {
    content: '▼';
    padding-left: 10px;
    margin-right: 5px;
  }
}

/* .dropdown-selected */
.dropdown-selected {
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-left: 5px;
  margin-right: -25px;
  padding-right: 25px;
  z-index: 10;
}

// .dropdown-selected:hover::after {
//   content: attr(data-title);
//   position: absolute;
//   left: 100%; /* Adjust to position the tooltip */
//   top: 50%;
//   transform: translateX(10px) translateY(-50%);
//   white-space: nowrap;
//   padding: 4px 8px;
//   border-radius: 4px;
//   background: black;
//   color: white;
//   font-size: 12px; /* Adjust as needed */
//   z-index: 1;
//   display: none;
// }

.dropdown-selected:hover::after {
  display: block;
}

.dropdown-selected-disabled {
  background-color: #d5d5d5;
  cursor: auto;
}

.dropdown-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 10px;
  cursor: pointer;
}

/* .dropdown-list */
.dropdown-list {
  width: 100%;
  max-width: 100%;
  z-index: 1000;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  user-select: none;
  .dropdown-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .search-input {
    padding: 3px 5px;
    margin: 5px;
    width: 92%;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .select-all {
    display: flex;
    justify-content: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 2px;
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

/* .dropdown-list li */
.dropdown-list li {
  padding: 2px 10px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}

.selected {
  background-color: #d5d5d5;
}

.option-container {
  overflow-y: auto;
  max-height: 150px;
}
