.wrapper {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .inputGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .blank {
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
  }
}
