/*left right modal*/
.modal.right_modal {
  position: fixed;
  z-index: 99999;
}

.modal.right_modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 450px;
  max-width: 85%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

@media (min-width: 576px) {
  .right_modal .modal-dialog {
    max-width: 100%;
  }
}

.modal.right_modal .modal-content {
  /*overflow-y: auto;
      overflow-x: hidden;*/
  height: 100vh !important;
}

.modal.right_modal .modal-body {
  padding: 15px 15px 30px;
}

/*Right*/
.modal.right_modal.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right_modal.fade.show .modal-dialog {
  right: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, .5);
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header.left_modal,
.modal-header.right_modal {
  padding: 10px 15px;
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

.modal_outer .modal-body {
  /*height:90%;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 91vh;
}

.revisions-container {
  width: 98% !important;
  margin: 5px auto;
  overflow: auto;
  max-height: 90%;
}

.modal-header {
  padding: 10px 15px !important;
  border-bottom: 1px solid #0ea0e9 !important;
}

.modal-header .h4
.modal-header {
  font-size: 1em;
}

.modal-footer {
  border-top: 1px solid #0ea0e9 !important;
}
