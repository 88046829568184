/* CustomTooltip.module.scss or CustomTooltip.css */
.custom-tooltip {
  position: fixed;
  background-color: rgba(
    60,
    60,
    60,
    0.9
  ); /* Dark background with some transparency */
  color: #fff; /* White text */
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.custom-tooltip.show {
  opacity: 1;
}
