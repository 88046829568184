ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.treeRoot {
  height: 100%;
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e8f0fe;
}

.root {
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  padding-inline-end: 8px;
}

.expandIconWrapper {
  align-items: center;
  /* font-size: 0; */
  display: flex;
  /* height: 24px; */
  justify-content: center;
  /* width: 24px; */
  transition: transform linear 0.1s;
}

.labelGridItem {
  padding-inline-start: 8px;
  font-size: medium;
  position: relative;
  /* fix text overflow issue */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 200px; */ /* Adjust the max-width as needed */
}

.iconAnimation {
  transition: all 1s ease-in-out;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 10px 0;
  .button {
    height: 100%;
  }
}

.contextmenu {
  margin: 0;
  padding: 0px !important;
  width: auto !important;
}

.contextmenuText {
  padding-left: 10px;
}

.editGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  z-index: 50;
}

.input {
  height: 30px;
}

.okButton {
  background-color: #009ca6;
  border-color: #009ca6;
}

.selectedText {
  color: #190593;
}

.unpublished {
  color: #ff0000;
}
