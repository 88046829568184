.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileType {
  @extend .flex-center;
  height: 100%;
  width: 30px;
  border-radius: 5px;
  border: 1px solid lightgray;
  svg {
    color: white;
    height: 100%;
    width: 30px;
    path {
      height: 100%;
      width: 30px;
    }
  }
}
.image {
  background-color: #d8d4e1;
}
.video,
.csv,
.xls {
  background-color: #c4ebdd;
}
.word {
  background-color: #50b5ff;
}
.ppt {
  background-color: #f5523c;
}
.pdf {
  background-color: #f5523c;
}
.default {
  background-color: #fff9e9;
  svg {
    color: #ffb81d;
  }
}
