.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.label {
  font-size: 14px;
  font-weight: 500;
  width: 20%;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.input {
  display: flex;
  width: 100%;
  font-size: 14px; /*15px; */
  padding-right: 20px;
  border: 1px solid #c8c8cb;
}
.inputWrapper {
  position: relative;
  flex: 1;
}
.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: gray;
}
