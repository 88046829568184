.imageContainer {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  height: 20px;
  width: 100%;
  text-align: center;
  img {
    height: 20px;
    width: 20px;
  }
}
