.nameCol {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
  .title {
    line-height: 16px;
    font-weight: bold;
  }
  .updated {
    color: rgb(116, 115, 115);
  }
}
.updateCol {
  @extend .nameCol;
  width: 100%;
}
.urlSpan {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  font-weight: bold;
}
.copyIcon {
  cursor: pointer;
  margin-right: 5px;
}

.document-cell {
  height: 100%;
}
