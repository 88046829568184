.searchContainer {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  border: 1px solid rgb(226, 223, 223);
  .edit {
    color: #009ca6;
    font-size: 12px;
    font-weight: 500;
  }
  .searchInput {
    display: flex;
    background-color: white;
    gap: 3px;
    flex: 1;
    align-items: center;
    padding: 0.3rem;
    input {
      background-color: transparent;
      border: none;
      font-size: 14px;
      width: 100%;
    }
  }
}
