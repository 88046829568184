.modal-content {
  background-color: #fff;
  /* Change as per your theme */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  /* Light border for the header */
  padding: 15px;
}

.modal-body {
  padding: 20px;
}

.modal-footer-publish {
  border-top: 1px solid #dee2e6;
  /* Light border for the footer */
  padding: 15px;
  text-align: right;
}

.modal-footer-publish .btn {
  margin-left: 10px;
}

.modal-footer-publish .btn-primary {
  background-color: #007bff;
  /* Primary color for confirm button */
  border-color: #007bff;
}

.modal-footer-publish .btn-secondary {
  background-color: #6c757d;
  /* Secondary color for cancel button */
  border-color: #6c757d;
}

.checkbox-list {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  max-width: 400px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox-list .btn-sm {
  align-self: flex-end;
  margin-top: 10px;
}

.checkbox-list-title {
  margin-bottom: 15px;
  color: #333;
  font-size: 1.1rem;
  text-align: center;
}

.checkbox-items {
  margin-bottom: 20px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-checkbox {
  accent-color: #007bff;
  margin-right: 10px;
}

.checkbox-label {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

.publish-button {
  background-color: #007bff;
  /* Primary button color */
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  margin-top: 15px;
}

.publish-button:hover {
  background-color: #0056b3;
}