/* .autoeditor-container */
.autoeditor-container {
  margin-bottom: 1px;
  padding: 0 !important;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
}

/* .autoeditor-selected */
.autoeditor-selected {
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  // padding-left: 5px;
  // margin-right: -25px;
  // padding-left: 5px;
  // padding-right: 5px;

  z-index: 10;
}

.search-autoeditor {
  width: 100%;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: inherit;
}
.search-autoeditor:focus {
  background-color: white;
}

.search-autoeditor.full-width {
  width: 100%;
  border: none;
}

.autoeditor-icon {
  width: 20%;
  background-color: transparent;
  border: none;
}

.autoeditor-selected:hover::after {
  content: attr(data-title);
  position: absolute;
  left: 100%; /* Adjust to position the tooltip */
  top: 50%;
  transform: translateX(10px) translateY(-50%);
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  background: black;
  color: white;
  font-size: 12px; /* Adjust as needed */
  z-index: 1;
  display: none;
}

.autoeditor-selected:hover::after {
  display: block;
}

.autoeditor-selected-disabled {
  background-color: #d5d5d5;
  cursor: auto;
}

/* .autoeditor-list */
.autoeditor-list {
  z-index: 1000;
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  user-select: none;
  .autoeditor-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* .autoeditor-list li */
.autoeditor-list li {
  padding: 2px 10px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background-color: #f5f5f5;
  }
}

.selected {
  background-color: #d5d5d5;
}
