.formOut {
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
}
.dropdown {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 10px;
  align-items: center;
}
.label {
  font-size: 14px;
  font-weight: 500;
  width: 20%;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.detailView {
  display: flex;
  margin-bottom: 10px;
}
.detailMain {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.detailRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
  border-bottom: 1px solid #c8c8cb;
}
.deatialHeader {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #c8c8cb;
  padding-left: 10px;
  padding-right: 10px;
  height: 38px;
  align-items: center;
}
.headerLabel {
  font-size: 14px;
  font-weight: 500;
}
.detailHeaderItem {
  flex: 1;
  font-size: 12px;
  color: gray;
  font-weight: 500;
}
.detailTable {
  margin-top: 10px;
  height: 250px;
}
.detailItem {
  display: flex;
  flex: 1;
  align-items: center;
}
.detailInput {
  border: 1px solid #c8c8cb;
  padding-left: 5px;
  border-radius: 3px;
  height: 38px;
  width: 100%;
  font-size: 14px;
}
.dropdownIcon {
  color: #ccc;
  font-weight: 600;
  transform: scale(0.8);
}
.switches {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 70px;
  margin-bottom: 10px;
}
.childSwitch {
  flex: 1 0 45%;
}
